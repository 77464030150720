const App = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000",
      }}
    >
      <img
        width={400}
        height={400}
        src="/assets/comingsoon.jpg"
        alt="coming-soon"
      />
    </div>
  );
};

export default App;
